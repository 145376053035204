<template>
  <div
    class="zy-gant-table"
    :style="{ width: tableWidth }"
  >
    <el-table
      ref="table"
      class="gant-table"
      height="100%"
      row-key="id"
      lazy
      :load="load"
      :data="tableList"
      :header-cell-style="headerStyle"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      @expand-change="handleSelectionChange"
      @scroll.native="gantScroll"
    >
      <slot></slot>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableList: [],
      row_scrollTop: 0,
    }
  },
  props: {
    //数据列表
    list: {
      type: Array,
      default: () => [],
    },
    //表格的宽度
    width: {
      type: String | Number,
      default: "50%",
    },
    scrollY: {
      type: Number,
      default: 0,
    },
    load: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    tableWidth() {
      //如果不是数字，如2px、50%等
      if (isNaN(this.width)) return this.width
      return `${this.width}px`
    },
  },
  watch: {
    list: {
      deep: true,
      immediate: true,
      handler(val) {
        this.tableList = JSON.parse(JSON.stringify(val))
      },
    },
    scrollY: {
      immediate: true,
      handler(val) {
        if (val == this.row_scrollTop) return
        this.row_scrollTop = val
        this.$nextTick(() => {
          this.$refs.table.bodyWrapper.scrollTop = val
        })
      },
    },
  },
  created() {
    this.$nextTick(() => {
      this.$refs.table.bodyWrapper.addEventListener("scroll", this.tableScroll, true)
    })
  },
  methods: {
    handleSelectionChange(row, expand) {
      row.expand = expand
      this.tableList = JSON.parse(JSON.stringify(this.tableList))
      const locale_obj = this.findRow(row, this.tableList)
      if (locale_obj) {
        locale_obj.expand = expand
      }
      this.$emit("update:list", this.tableList)
    },
    findRow(row, list) {
      for(let i = 0; i < list.length; i++) {
        const obj = list[i]
        if (obj.id === row.id) {
          return obj
        }
        if (obj.children && obj.children.length) {
          const result = this.findRow(row, obj.children)
          if (result) {
            return result
          }
        }
      }
    },
    headerStyle() {
      return {
        background: "#1E5087",
        fontWeight: "bold",
        fontSize: "14px",
        color: "#FFFFFF",
        lineHeight: "21px",
        borderBottom: "unset",
        height: "60px",
        padding: "unset",
      }
    },
    tableScroll(event) {
      this.row_scrollTop = event.target.scrollTop
      this.$emit("update:scrollY", this.row_scrollTop)
    },
  },
}
</script>

<style lang="less" scoped>
.zy-gant {
  &-table {
    overflow: hidden;
    flex-shrink: 0;
    font-size: 0;
    border-right: rgba(255, 255, 255, 0.1) 1px solid;
  }
}
</style>
<style>
.gant-table tr {
  background: transparent;
}
/* 鼠标移动上去改变颜色 */
.gant-table tr:hover > td {
  background-color: transparent !important;
}
/* row底部分割线划分 */
.gant-table td.el-table__cell {
  border-bottom: rgba(255, 255, 255, 0.1) 1px solid;
  line-height: 21px;
  height: 40px;
  box-sizing: border-box;
}
.gant-table td.el-table__cell {
  padding: 9px 0;
}
.gant-table td.el-table__cell > div {
  color: red;
  background: transparent;
  font-size: 14px;
  color: #ffffff;
  line-height: 20px;
  height: 20px;
}
.gant-table::before,
.gant-table,
.gant-table .el-table__expanded-cell {
  background: transparent;
}
.gant-table .el-table__expand-icon {
  background: #1e5087;
  color: #ffffff;
  margin-right: 12px!important;
}
.gant-table .el-table__body-wrapper::-webkit-scrollbar{
  width: 0px;
}
.gant-table th.el-table__cell.gutter{
  display: none;
}
.gant-table .el-table__body{
  width: 100%!important;
}
.gant-table .el-table__header{
  width: calc(100% + 20px)!important;
}
</style>
